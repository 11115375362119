<template>
    <div class="certificate-main" :style="{padding:userAgent==1?'0':'130px 410px 147px'}">
        <img :src="img" alt="">
    </div>
  </template>

<script>
export default {
  name: 'certificate',
  data () {
    return {
      img: require('@/assets/certificate1.jpg'),
      userAgent: 1
    }
  },
  created () {
    this.getUserAgent()
  },
  methods: {
    getUserAgent () {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isAndroid || isiOS) {
        this.userAgent = 1
      } else {
        this.userAgent = 2
      }
    }

  }
}
</script>

  <style lang="less">
  .certificate-main {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9;
    padding: 130px 410px 147px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: auto;
    }
  }
  </style>
